<template>
  <div class="site-wrapper" v-loading.fullscreen.lock="loading" element-loading-text="拼命加载中">
    <template v-if="!loading">
      <main-navbar v-if="!isIframeChild" />
      <main-sidebar v-if="!isIframeChild" />
      <div
        class="site-content__wrapper"
        :class="{'isiframe': isIframeChild }"
        :style="{ 'min-height': documentClientHeight + 'px', 'margin': sidebarFold?'0 0 0 50px':'0 0 0 176px','margin-left': isIframeChild?'0px':'176px','padding-top':isIframeChild?'0':'56px'}"
      >
        <main-content />
      </div>
    </template>
    
  </div>
</template>

<script>
import MainNavbar from './main-navbar.vue'
import MainSidebar from './main-sidebar.vue'
import MainContent from './main-content.vue'
export default {
  data () {
    return {
      loading: false
    }
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  computed: {
    isIframeChild: {
      get () {
        return this.$store.state.common.isIframeChild
      },
      set (val) {
        this.$store.commit('common/updateIsIframeChild', val)
      }
    },
    documentClientHeight: {
      get () {
        return this.$store.state.common.documentClientHeight
      },
      set (val) {
        this.$store.commit('common/updateDocumentClientHeight', val)
      }
    },
    sidebarFold: {
      get () {
        return this.$store.state.common.sidebarFold
      }
    }
  },
  created () {
    // this.getUserInfo()
  },
  mounted () {
    this.resetDocumentClientHeight()
    this.getIframe()
  },
  methods: {
    // 是否为iframe得子节点
    getIframe () {
      if (self !== top) {
        this.isIframeChild = true
      }
    },
    // 重置窗口可视高度
    resetDocumentClientHeight () {
      this.documentClientHeight = document.documentElement['clientHeight']
      window.onresize = () => {
        this.documentClientHeight = document.documentElement['clientHeight']
      }
    },
    // 获取当前管理员信息
    getUserInfo () {
      this.$http({
        url: this.$http.adornUrl('/sys/user/info'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.userId = data.user.userId
          this.userName = data.user.username
        }
      })
    }
  }
}
</script>
<style>

</style>
